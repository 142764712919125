
import { REQUEST_ADMIN_AUTH, ERROR_ADMIN_AUTH, SET_ADMIN_AUTH } from "./adminAuth.action_type"
const intialState = {
    loading: false,
    adminAuthData:{},
    errorAdminAuth: null,
}

const reducer = (state = intialState, action) => {
//  console.log(state,"errror admin Auth");
 
    switch (action.type) {
        case REQUEST_ADMIN_AUTH:
            return {
                ...state,
                loading: true,

            }
        case SET_ADMIN_AUTH:
            return {
                ...state,
                loading: false,
                adminAuthData: action.payload,
                error: null
            }
        case ERROR_ADMIN_AUTH:
            return {
                ...state,
                errorAdminAuth: action?.payload?.response?.data || action?.payload?.message,
            }
        default: return state
    }


}
export { reducer };